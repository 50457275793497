<template>
    <el-dialog v-model="dialog_opened">
        <el-form :model="form" ref="form" label-width="100px">
            <h4>广告组配置</h4>
            <template v-for="(g, i) in form.groups">
                <el-card shadow="never" style="margin: 5px" :header="`广告组${i}`" :body-style="{position: 'relative'}">
                    <el-icon @click="removeAdGroup(i)" size="24px"
                             style="position: absolute;top: 10px;right:10px;color: var(--el-color-danger);cursor: pointer;">
                        <CloseBold/>
                    </el-icon>
                    <el-form-item label="广告类型" :prop="`groups.${i}.type`"
                                  :rules="[{required: true, message: '请选择广告类型'}]">
                        <el-select v-model="g.type">
                            <el-option label="激励视频" value="reward"></el-option>
                            <el-option label="插页广告" value="interstitial"></el-option>
                            <el-option label="横幅广告" value="banner"></el-option>
                            <el-option label="原生广告" value="native"></el-option>
                            <el-option label="开屏广告" value="splash"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="广告位" :prop="`groups.${i}.placements`"
                                  :rules="[{required: true, message: '请选择广告位'}]">
                        <el-select v-model="g.placements" multiple allow-create filterable
                                   style="width:400px"></el-select>
                    </el-form-item>
                    <el-form-item label="聚合ID" :prop="`groups.${i}.units.${ii}.unit_id`" v-for="(u, ii) in g.units"
                                  :rules="[{required: true, message: '请输入聚合ID'}]">
                        <el-select v-model="u.channel" style="width: 100px" @change="checkMediation">
                            <el-option v-for="m in mediation_list" :value="m"></el-option>
                        </el-select>
                        <el-input style="width: 300px" v-model="u.unit_id"></el-input>
                        <el-icon style="color: var(--el-color-danger);cursor: pointer;margin: 5px"
                                 @click="removeAdUnit(g, ii)" v-if="ii>0">
                            <Delete/>
                        </el-icon>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="success" @click="addUnit(g)">添加聚合id</el-button>
                    </el-form-item>
                </el-card>
            </template>
            <el-button type="success" @click="addAdGroup">添加广告组</el-button>
            <h4 v-if="form.mediations.length > 0">聚合平台配置</h4>
            <template v-for="(m, i) in form.mediations">
                <el-card shadow="never" style="margin: 5px;" :header="`${m.channel}`">
                    <template v-if="m.channel === 'Max'">
                        <el-form-item label="SDK Key" :prop="`mediations.${i}.config.sdk_key`"
                                      :rules="[{required: true, message: '请输入SDK Key'}]">
                            <el-input v-model="m.config.sdk_key"></el-input>
                        </el-form-item>
                    </template>
                </el-card>
            </template>
        </el-form>
        <template #footer>
            <el-button @click="reset">取消</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {update} from "../../libs/utils";
import {ElMessageBox, ElMessage} from 'element-plus'

export default {
    name: "SdkExpGroup",
    data() {
        return {
            dialog_opened: false, editing: null, mediation_list: ['Max', 'Admob'],
            form: {
                mediations: [], groups: [],
                _count_placement: 0, _count_mediation: 0, _count_groups: 0,
            },
        }
    },
    methods: {
        edit(data) {
            this.dialog_opened = true;
            this.editing = data;
            this.$nextTick(_ => {
                update(this.form, data);
                if (this.form.groups.length < 1) {
                    this.addAdGroup();
                }
            });
        },
        refreshProperty() {
            this.form._count_mediation = this.form.mediations.length;
            this.form._count_groups = this.form.groups.length;
            const placement = [];
            this.form.groups.forEach(g => {
                g.placements.forEach(p => {
                    if (p && placement.indexOf(p) < 0) {
                        placement.push(p);
                    }
                });
            });
            this.form._count_placement = placement.length;
        },
        addAdGroup() {
            const ad_group = {placements: [], type: '', units: [], fake: 0};
            this.form.groups.push(ad_group);
            this.addUnit(ad_group);
        },
        checkMediation() {
            const channel = [];
            this.form.groups.forEach(g => {
                g.units.forEach(u => {
                    if (channel.indexOf(u.channel) < 0) {
                        channel.push(u.channel);
                    }
                })
            });
            channel.forEach(c => {
                let exists = false;
                this.form.mediations.forEach(m => {
                    if (m.channel === c) {
                        exists = true;
                    }
                });
                if (!exists) {
                    const mediation = {channel: c, config: {}}
                    if (c === 'Max') {
                        mediation.config.sdk_key = '';
                    }
                    this.form.mediations.push(mediation);
                }
            });
            const remove = [];
            this.form.mediations.forEach(m => {
                let exists = false;
                channel.forEach(c => {
                    if (m.channel === c) {
                        exists = true;
                    }
                });
                if (!exists) {
                    remove.push(m);
                }
            });
            remove.forEach(r => {
                this.form.mediations.splice(this.form.mediations.indexOf(r), 1);
            });
        },
        addUnit(group) {
            group.units.push({channel: 'Max', unit_id: ''});
            this.checkMediation();
        },
        removeAdUnit(g, i) {
            g.units.splice(i, 1);
            this.checkMediation();
        },
        removeAdGroup(i) {
            ElMessageBox.confirm('确定要删除该广告组吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(_ => {
                this.form.groups.splice(i, 1);
                this.checkMediation();
            }).catch(_ => {
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const placement = {}, unit = {};
                    this.form.groups.forEach((g, i) => {
                        g.placements.forEach(p => {
                            if (placement[p] !== undefined) {
                                ElMessage.error(`广告位:${p}在广告组${placement[p]}和${i}中重复！`)
                                valid = false;
                            } else {
                                placement[p] = i;
                            }
                        })
                    });
                    // this.form.groups.forEach((g, i) => {
                    //     g.units.forEach(u => {
                    //         if (unit[u.unit_id] !== undefined) {
                    //             ElMessage.error(`聚合ID：${u.unit_id}在广告组${unit[u.unit_id]}和${i}中重复！`)
                    //             valid = false;
                    //         } else {
                    //             unit[u.unit_id] = i;
                    //         }
                    //     })
                    // });
                    if (valid) {
                        this.refreshProperty();
                        update(this.editing, this.form);
                        this.reset();
                    }
                }
            })
        }
    },
}
</script>

<style scoped>

</style>